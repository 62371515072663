import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import {
  staffingDeliveryModel,
  talentSolutionService,
} from "../../constant/service";
import ServiceFlipCard from "../../components/ServiceFlipCard";
import TalentStaffingModel from "../../components/TalentStaffingModel";

const TalentSolution = () => {
  const header =
    "End-to-End Talent Solutions: Connecting You with Top Talent for Sustainable Growth and High-Performance Teams Across Industries";
  const strong = "";
  const description =
    "At BNI, we provide comprehensive talent solutions that help you find, hire, and nurture the best professionals. From talent sourcing to onboarding and workforce optimization, our services are designed to align with your company’s specific needs, ensuring you build a team that drives success and innovation.";

  return (
    <div>
      <CommonBanner currentPage="TALENT SOLUTION" heading="TALENT SOLUTION" />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <ServiceFlipCard services={talentSolutionService} />
      <TalentStaffingModel />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default TalentSolution;
