import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import TalentStaffingModel from "../../components/TalentStaffingModel";

const ProfessionalStaffing = () => {
  const header =
    "Professional Staffing Services: Delivering Top Talent for Critical Roles Across Diverse Industries";
  const strong = "";
  const description =
    "Our Professional Staffing services at BNI connect you with experienced professionals for key roles within your organization. From administrative support to specialized functions, we source and place candidates who bring both expertise and a strong work ethic, helping your business achieve operational efficiency and excellence.";

  return (
    <div>
      <CommonBanner
        currentPage="PROFESSIONAL STAFFING"
        heading="PROFESSIONAL STAFFING"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <TalentStaffingModel />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default ProfessionalStaffing;
