import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceFlipCard = ({ services }) => {
  const navigate = useNavigate();

  return (
    <div className="section_3_lower">
      {services.map((item, i) => {
        return (
          <div className="flip_card" key={i}>
            <div className="flip_card_inner">
              <div className="flip_card_front">
                <div className="section_3_card_icon">
                  <img
                    src={item.icon}
                    alt=""
                    srcSet=""
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="section_3_card_title">{item.title}</div>
                <div
                  className="section_3_card_description"
                  style={{ width: "90%" }}
                >
                  {item.description}
                </div>
              </div>
              <div className="flip_card_back">
                <button
                  className="flip_card_back_button"
                  type="button"
                  onClick={() => {
                    navigate(item.link);
                  }}
                >
                  READ MORE
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServiceFlipCard;
