import React from "react";
import AllServicesCommonSection from "./AllServicesCommonSection";
import { staffingDeliveryModel } from "../constant/service";

const TalentStaffingModel = () => {
  return (
    <div
      style={{
        padding: "0 4%",
      }}
    >
      <AllServicesCommonSection
        header="Talent Staffing Delivery Models"
        // strong={strong}
        // description={description}
      />
      <div className="webdesigndevelopment_section_card_container">
        {staffingDeliveryModel.map((item, i) => {
          return (
            <div className="webdesigndevelopment_card_container" key={i}>
              <div className="webdesigndevelopment__card_icon">
                <img src={item.icon} alt="" srcSet="" />
              </div>
              <div className="section_3_card_title">{item.title}</div>
              <div className="webdesigndevelopment_card_description">
                {item.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TalentStaffingModel;
