import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import TalentStaffingModel from "../../components/TalentStaffingModel";

const ITProfessionalStaffing = () => {
  const header = "IT Staffing Solutions for Your Business";
  const strong = "";
  const description =
    "At Business Needs Inc., we offer expert IT staffing solutions to help you find the perfect fit for your team. Our experienced recruiting team has the knowledge and expertise to identify individuals who meet your exact requirements, whether you need them for a short-term project or a long-term assignment. Save time and money while filling your position of need with the perfect fit for your team.";

  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner currentPage="IT Staffing" heading="IT Staffing" />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <TalentStaffingModel />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default ITProfessionalStaffing;
