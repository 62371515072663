import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import { HealthcareStaffingData } from "../../constant/service";
import TalentStaffingModel from "../../components/TalentStaffingModel";

const HealthcareStaffing = () => {
  const header =
    "BNI Healthcare specializes in connecting you with a skilled and talented medical workforce, catering to diverse opportunities and ensuring you find the perfect fit for your needs.";
  const strong = "";
  const description =
    "BNI Healthcare boasts a team of experts dedicated to crafting specialized staffing solutions for healthcare organizations. Our recruitment services span a broad spectrum of needs, from physicians and locums to registered nurses, allied healthcare professionals, and healthcare assistants.";

  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="HEALTHCARE STAFFING"
        heading="HEALTHCARE STAFFING"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <TalentStaffingModel />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default HealthcareStaffing;
