import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";

const RPO = () => {
  const header =
    "RPO Services: Streamlining Recruitment with End-to-End Talent Acquisition Solutions";
  const strong = "";
  const description =
    "Our RPO (Recruitment Process Outsourcing) services at BNI help you optimize your hiring process, reduce time-to-hire, and improve the quality of your talent pool. From job requisition and candidate sourcing to onboarding and compliance, we manage the entire recruitment lifecycle, allowing you to focus on business growth while we handle your staffing needs with precision and efficiency.";

  return (
    <div>
      <CommonBanner currentPage="RPO" heading="RPO" />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <div
        style={{
          padding: "0 4%",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div className="webdesigndevelopment_section_card_container">
          {/* {HealthcareStaffingData.map((item, i) => {
            return (
              <div className="webdesigndevelopment_card_container" key={i}>
                <div className="webdesigndevelopment__card_icon">
                  <img src={item.icon} alt="" srcSet="" />
                </div>
                <div className="section_3_card_title">{item.title}</div>
                <div className="webdesigndevelopment_card_description">
                  {item.description}
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default RPO;
