export const aboutBrief = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/9321/9321549.png",
    title: "Talent solutions",
    link: "/services/talentsolution",
    description:
      "Your people are your greatest asset. Ensure you get the right support at the right time to drive positive outcomes.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/2721/2721688.png",
    title: "IT Consulting Services",
    link: "/services/itconsultingservices",
    description:
      "The acceleration of digital transformation continues. Obtain trusted, flexible, end-to-end IT solutions that are built to keep up.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/476/476700.png",
    title: "Offshore Development Services",
    link: "/services/offshoredevelopment",
    description:
      "Our skilled support team is here to efficiently and effectively handle all your professional needs",
  },
];
