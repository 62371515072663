import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import ServiceFlipCard from "../../components/ServiceFlipCard";
import { iTConsultingService } from "../../constant/service";

const ITConsultingServices = () => {
  const header =
    "Expert IT Consulting Services: Empowering Your Business with Cutting-Edge Technology Solutions and Strategic Insights";
  const strong = "";
  const description =
    "Our IT Consulting Services at BNI, help businesses optimize their technology infrastructure, streamline operations, and drive digital transformation. From system integration to cloud solutions and cybersecurity, our experienced consultants provide tailored strategies that align with your unique objectives, ensuring you stay ahead in a rapidly evolving tech landscape.";

  return (
    <div>
      <CommonBanner
        currentPage="IT CONSULTING SERVICES"
        heading="IT CONSULTING SERVICES"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <ServiceFlipCard services={iTConsultingService} />

      {/* <div
        style={{
          padding: "0 4%",
        }}
      >
        <div className="webdesigndevelopment_section_card_container">
          {HealthcareStaffingData.map((item, i) => {
            return (
              <div className="webdesigndevelopment_card_container" key={i}>
                <div className="webdesigndevelopment__card_icon">
                  <img src={item.icon} alt="" srcSet="" />
                </div>
                <div className="section_3_card_title">{item.title}</div>
                <div className="webdesigndevelopment_card_description">
                  {item.description}
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default ITConsultingServices;
