import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import ServiceFlipCard from "../../components/ServiceFlipCard";
import { staffingDeliveryModel, staffingService } from "../../constant/service";
import TalentStaffingModel from "../../components/TalentStaffingModel";

const Staffing = () => {
  const header =
    "Staffing Solutions: Building Strong Teams with the Right Talent, When You Need It Most";
  const strong = "";
  const description =
    "At BNI, we provide flexible staffing solutions to meet your dynamic workforce needs. Whether you're looking for temporary, permanent, or contract staff, our expert team sources and places highly skilled professionals that fit seamlessly into your company culture. We ensure you have the right people at the right time to support your business goals and growth.";

  return (
    <div style={{ background: "" }}>
      <CommonBanner currentPage="STAFFING" heading="STAFFING" />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <ServiceFlipCard services={staffingService} />

      <TalentStaffingModel />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default Staffing;
