import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";

const OffshoreDevelopment = () => {
  const header =
    "Offshore Development Services: Access Top Talent and Scalable Solutions for Faster, Cost-Effective Growth";
  const strong = "";
  const description =
    "At BNI, our Offshore Development Services provide businesses with access to a global pool of skilled developers, enabling you to scale projects quickly and cost-effectively. Whether you're looking for custom software development, mobile app solutions, or IT support, our offshore teams work seamlessly with your in-house staff to deliver high-quality results on time and within budget.";

  return (
    <div>
      <CommonBanner
        currentPage="OFFSHORE DEVELOPMENT"
        heading="OFFSHORE DEVELOPMENT"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <div
        style={{
          padding: "0 4%",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div className="webdesigndevelopment_section_card_container">
          {/* {HealthcareStaffingData.map((item, i) => {
            return (
              <div className="webdesigndevelopment_card_container" key={i}>
                <div className="webdesigndevelopment__card_icon">
                  <img src={item.icon} alt="" srcSet="" />
                </div>
                <div className="section_3_card_title">{item.title}</div>
                <div className="webdesigndevelopment_card_description">
                  {item.description}
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default OffshoreDevelopment;
