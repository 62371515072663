import React from "react";
import { services } from "../constant/service";
import ServiceFlipCard from "./ServiceFlipCard";

const ServiceSection = () => {
  return (
    <div className="section_3_container">
      <div className="section_3_upper">
        <div className="section_3_upper_left">
          <p className="section_2_p_1">
            <div className="triangle"></div>OUR SERVICES
          </p>
          <p className="section_2_p_2">Excellent Services for Your Business</p>
          <div className="section_3_upper_left_hr"></div>
        </div>
        <hr className="section_3_hr" />
        <div className="section_3_upper_right">
          <p className="section_2_p_3">
            At Business Needs Inc. we specialize in Information Technology
            services and Talent solutions for companies globally. We believe
            that each client is unique, and there is no such thing as
            one-size-fits all in the world of technology.
          </p>
        </div>
      </div>
      <ServiceFlipCard services={services} />
    </div>
  );
};

export default ServiceSection;
