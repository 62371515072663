export const servicesByCategory = [
  {
    title: "Talent Solution",
    link: "/services/talentsolution",
    subCategory: [
      {
        title: "Staffing",
        link: "/services/staffing",
        subCategory: [
          { title: "IT Staffing", link: "/services/itprofessionalstaffing" },
          {
            title: "Healthcare Staffing",
            link: "/services/healthcarestaffing",
          },
          {
            title: "Engineering Staffing",
            link: "/services/engineeringstaffing",
          },
          {
            title: "Professional Staffing",
            link: "/services/professionalstaffing",
          },
          {
            title: "Creative and Marketing Staffing",
            link: "/services/creativeandmarketingstaffing",
          },
        ],
      },
      {
        title: "RPO",
        link: "/services/rpo",
        su1bCategory: [],
      },
      {
        title: "Payroll Services",
        link: "/services/payrollservices",
        subCategory: [],
      },
    ],
  },
  {
    title: "IT Consulting Services",
    link: "/services/itconsultingservices",

    subCategory: [
      {
        title: "Web Application Development",
        link: "/services/webdesignanddevelopment",
      },
      {
        title: "Mobile Application Development",
        link: "/services/mobileapplicationdevelopment",
      },
      { title: "Digital Marketing", link: "/services/digitalmarketing" },
      { title: "QA Testing", link: "/services/qatesting" },
    ],
  },
  {
    title: "Offshore Development Services",
    link: "/services/offshoredevelopment",
    subCategory: [
      {
        title: "offshore development Center",
        link: "/services/offshoredevelopment",
      },
      {
        title: "Dedicated Team",
        link: "/services/offshoredevelopment",
      },
    ],
  },
];

export const services = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "IT Staffing",
    description:
      "Get ready to take your team to new heights with our expert recruiting staff - whether you need short-term or long-term staffing solutions",
    link: "/services/itprofessionalstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/3246/3246843.png",
    title: "Healthcare Staffing",
    description:
      "BNI Healthcare boasts a team of experts dedicated to crafting specialized staffing solutions for healthcare organizations.",
    link: "/services/healthcarestaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Professional Staffing",
    description:
      "We specialize in providing professional staffing services, offering tailored solutions to meet your organization's unique needs. Our team connects you with top-tier talent, ensuring a seamless hiring process and helping your business thrive with skilled professionals. Let us handle your staffing challenges, so you can focus on success",
    link: "/services/professionalstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Engineering Staffing",
    description:
      "We offer expert engineering staffing services, providing skilled professionals to meet your project demands. Whether you need temporary or permanent placements, our team connects you with top engineering talent, ensuring quality, efficiency, and timely results. Trust us to deliver the right engineers for your business success.",
    link: "/services/engineeringstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Creative and Marketing Staffing",
    description:
      "We specialize in creative and marketing staffing, connecting you with top talent to drive your brand forward. From graphic designers to digital marketers, our team delivers skilled professionals tailored to your unique needs. Let us help you build a strong, innovative team that propels your business success.",
    link: "/services/creativeandmarketingstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/2335/2335265.png",
    title: "Web Application Development",
    description:
      "Enhance your online presence and attract more customers with our professional web design and development services.",
    link: "/services/webdesignanddevelopment",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11357/11357467.png",
    title: "Mobile Application Development",
    description:
      " Maximize your business potential with software that is tailored to your unique needs that enhances your operations.",
    link: "/services/mobileapplicationdevelopment",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11357/11357467.png",
    title: "Digital Marketing",
    description:
      " Maximize your business potential with software that is tailored to your unique needs that enhances your operations.",
    link: "/services/digitalmarketing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11357/11357467.png",
    title: "QA Testing",
    description:
      " Maximize your business potential with software that is tailored to your unique needs that enhances your operations.",
    link: "/services/qatesting",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "RPO",
    description:
      "Our RPO (Recruitment Process Outsourcing) services streamline your hiring process by providing expert talent acquisition solutions. We manage end-to-end recruitment, from sourcing to onboarding, ensuring you secure top candidates efficiently. Focus on your core business while we handle your staffing needs with precision and expertise.",
    link: "/services/rpo",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Payroll Services",
    description:
      "Our payroll services ensure accurate, timely, and compliant management of your employee payroll. From calculating wages to handling tax filings, we streamline the entire process, allowing you to focus on growing your business. Trust us to deliver hassle-free payroll solutions tailored to your company’s needs.",
    link: "/services/payrollservices",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11357/11357467.png",
    title: "Offshore Development Services",
    description:
      "Our Offshore Development Services provide cost-effective, high-quality software development solutions with a global talent pool. We help businesses scale by offering expert developers, project management, and technical expertise tailored to your needs. Enhance your capabilities while reducing costs with our reliable, efficient offshore development team.",
    link: "/services/offshoredevelopment",
  },
  // {
  //   icon: "https://cdn-icons-png.flaticon.com/512/2721/2721688.png",
  //   title: "IT Transformation",
  //   description:
  //     "Transform your business processes and gain a competitive edge while reducing costs",
  //   link: "/services/ittransformation",
  // },
  // {
  //   icon: "https://cdn-icons-png.flaticon.com/512/2906/2906206.png",
  //   title: "Cloud Transition",
  //   description:
  //     " Elevate your business with cloud transition services that prioritize privacy, security, and data management",
  //   link: "/services/cloudtransition",
  // },
  // {
  //   icon: "https://cdn-icons-png.flaticon.com/512/1119/1119007.png",
  //   title: "Application Security",
  //   description:
  //     "Protect your systems from external threats with our expert application security services",
  //   link: "/services/applicationsecurity",
  // },
  // {
  //   icon: "https://cdn-icons-png.flaticon.com/512/9708/9708984.png",
  //   title: "Database Management",
  //   description:
  //     "Maximize your revenue and streamline your operations with our expert data management services.",
  //   link: "/services/databasemanagement",
  // },
];

export const talentSolutionService = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "IT Staffing",
    description:
      "Get ready to take your team to new heights with our expert recruiting staff - whether you need short-term or long-term staffing solutions",
    link: "/services/itprofessionalstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/3246/3246843.png",
    title: "Healthcare Staffing",
    description:
      "BNI Healthcare boasts a team of experts dedicated to crafting specialized staffing solutions for healthcare organizations.",
    link: "/services/healthcarestaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Professional Staffing",
    description:
      "We specialize in providing professional staffing services, offering tailored solutions to meet your organization's unique needs. Our team connects you with top-tier talent, ensuring a seamless hiring process and helping your business thrive with skilled professionals. Let us handle your staffing challenges, so you can focus on success",
    link: "/services/professionalstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Engineering Staffing",
    description:
      "We offer expert engineering staffing services, providing skilled professionals to meet your project demands. Whether you need temporary or permanent placements, our team connects you with top engineering talent, ensuring quality, efficiency, and timely results. Trust us to deliver the right engineers for your business success.",
    link: "/services/engineeringstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Creative and Marketing Staffing",
    description:
      "We specialize in creative and marketing staffing, connecting you with top talent to drive your brand forward. From graphic designers to digital marketers, our team delivers skilled professionals tailored to your unique needs. Let us help you build a strong, innovative team that propels your business success.",
    link: "/services/creativeandmarketingstaffing",
  },

  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "RPO",
    description:
      "Our RPO (Recruitment Process Outsourcing) services streamline your hiring process by providing expert talent acquisition solutions. We manage end-to-end recruitment, from sourcing to onboarding, ensuring you secure top candidates efficiently. Focus on your core business while we handle your staffing needs with precision and expertise.",
    link: "/services/rpo",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Payroll Services",
    description:
      "Our payroll services ensure accurate, timely, and compliant management of your employee payroll. From calculating wages to handling tax filings, we streamline the entire process, allowing you to focus on growing your business. Trust us to deliver hassle-free payroll solutions tailored to your company’s needs.",
    link: "/services/payrollservices",
  },
];
export const staffingService = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "IT Staffing",
    description:
      "Get ready to take your team to new heights with our expert recruiting staff - whether you need short-term or long-term staffing solutions",
    link: "/services/itprofessionalstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/3246/3246843.png",
    title: "Healthcare Staffing",
    description:
      "BNI Healthcare boasts a team of experts dedicated to crafting specialized staffing solutions for healthcare organizations.",
    link: "/services/healthcarestaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Professional Staffing",
    description:
      "We specialize in providing professional staffing services, offering tailored solutions to meet your organization's unique needs. Our team connects you with top-tier talent, ensuring a seamless hiring process and helping your business thrive with skilled professionals. Let us handle your staffing challenges, so you can focus on success",
    link: "/services/professionalstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Engineering Staffing",
    description:
      "We offer expert engineering staffing services, providing skilled professionals to meet your project demands. Whether you need temporary or permanent placements, our team connects you with top engineering talent, ensuring quality, efficiency, and timely results. Trust us to deliver the right engineers for your business success.",
    link: "/services/engineeringstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "Creative and Marketing Staffing",
    description:
      "We specialize in creative and marketing staffing, connecting you with top talent to drive your brand forward. From graphic designers to digital marketers, our team delivers skilled professionals tailored to your unique needs. Let us help you build a strong, innovative team that propels your business success.",
    link: "/services/creativeandmarketingstaffing",
  },
];
export const iTConsultingService = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/2335/2335265.png",
    title: "Web Application Development",
    description:
      "Enhance your online presence and attract more customers with our professional web design and development services.",
    link: "/services/webdesignanddevelopment",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11357/11357467.png",
    title: "Mobile Application Development",
    description:
      " Maximize your business potential with software that is tailored to your unique needs that enhances your operations.",
    link: "/services/mobileapplicationdevelopment",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11357/11357467.png",
    title: "Digital Marketing",
    description:
      " Maximize your business potential with software that is tailored to your unique needs that enhances your operations.",
    link: "/services/digitalmarketing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11357/11357467.png",
    title: "QA Testing",
    description:
      " Maximize your business potential with software that is tailored to your unique needs that enhances your operations.",
    link: "/services/qatesting",
  },
];
export const staffingDeliveryModel = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/8299/8299880.png",
    title: "Direct Hire",
    description:
      "Direct Hire staffing involves recruiting and placing full-time employees directly with your company. Our team works closely with you to understand your specific hiring needs and company culture, sourcing highly qualified candidates who are the right fit for your organization. Once hired, the candidate becomes a permanent member of your team, and we offer a guarantee on the placement to ensure a successful integration into your workforce.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/9299/9299850.png",
    title: "Contract/Temporary Staffing",
    description:
      "Contract or Temporary Staffing is designed for companies that need skilled professionals for short-term projects or to cover temporary gaps, such as seasonal demands or employee leave. In this model, we provide you with qualified workers who are employed by our staffing agency for a defined period. You get the flexibility to meet your immediate staffing needs while we handle the recruitment, onboarding, and HR responsibilities.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/10816/10816122.png",
    title: "Contract-to-Hire/Temp-to-Perm",
    description:
      "Contract-to-Hire (or Temp-to-Perm) allows companies to evaluate a candidate's performance and fit within the organization before committing to a permanent hire. We place candidates in temporary roles, giving you the flexibility to assess their skills and work ethic over a set period. If the candidate is a good match, you have the option to convert them into a full-time employee, making this a great solution for minimizing hiring risks while ensuring a long-term fit.",
  },
];

export const AccountingServicesData = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/8913/8913116.png",
    title: "Accounts Payable",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/7591/7591422.png",
    title: "Taxation",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/6057/6057901.png",
    title: "book keeping & accountancy",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/4306/4306889.png",
    title: "Invoicing",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/10930/10930823.png",
    title: "Bank Audit (Monthly & Quarterly)",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/897/897219.png",
    title: "Inventory Audit",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11139/11139294.png",
    title: "Sale Forcasting",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/8913/8913116.png",
    title: "W2 Payrolls",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1159/1159633.png",
    title: "1099",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/6698/6698239.png",
    title: "Accounts Receivable",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11094/11094266.png",
    title: "Payment Tracking",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/10216/10216823.png",
    title: "Bank Reconciliation. (Debit card & Credit cards)",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11482/11482372.png",
    title: "Sale Orders",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11518/11518892.png",
    title: "Inventory Management",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11268/11268992.png",
    title: "Budget Accounts Management",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/5441/5441161.png",
    title: "Sale Tax Deposit Quarterly or yearly",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/7377/7377207.png",
    title: "Payrolls Deposit weekly or biweekly",
    description: "",
  },
];

export const HealthcareStaffingData = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/8299/8299880.png",
    title: "Access to a Qualified Talent Pool",
    description:
      "Partnering with IMCS Healthcare provides access to a large pool of pre-screened, qualified candidates, saving your organization time and effort.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/9299/9299850.png",
    title: "Lighten Your Recruitment Burden",
    description:
      "Partnering with IMCS Healthcare allows you to delegate the responsibility of sourcing, screening, and interviewing candidates to our team of staffing experts.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/10816/10816122.png",
    title: "Enhanced Cost Efficiency",
    description:
      "By expertly managing payroll and HR-related expenses, we alleviate your company's financial burdens and optimize workforce management, resulting in significant cost savings and improved productivity.",
  },
];

export const ApplicationSecurityData = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/8299/8299880.png",
    title: "Security Inbox",
    description:
      "Replacing your security@ email address with our services limits your risk and provides centralized access to your vulnerability reports.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/9299/9299850.png",
    title: "Automated Workflows",
    description:
      "Workflow integrations and an advanced API allow security and engineering teams to avoid changing the way they work.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/10816/10816122.png",
    title: "On-Demand and Continuous Testing",
    description:
      "Whether looking for a project-based test or a continuous 365/24/7 security solution, we can create a program to fit your needs.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/5455/5455956.png",
    title: "Self-Managed vs Fully-Managed",
    description:
      "We are the only provider to offer the choice of managing a program on your own or leveraging our ‚Äòwhite-glove‚Äô services.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/897/897219.png",
    title: "Actionable & Compliant",
    description:
      "Business Needs Inc can create a dashboard to consolidate your vulnerability data to identify risks before they can be exploited.",
  },
];

export const WebDesignAndDevelopmentData = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1006/1006585.png",
    title: "Strategy",
    description:
      "Every business has different needs. The team at Business Needs Inc meet those needs with a combination of vision and tons of experience. We can help your business conceive and implement a branding and marketing strategy that will exceed your expectations.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/2421/2421341.png",
    title: "Stunning Creative Design",
    description:
      "Building quality UX designs really gets our creative juices flowing. Every website should be a visual work of art, with functionality and a vision that reflects your image and brand. We create every website to stand out from the crowd and be as unique as the company it represents.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/897/897219.png",
    title: "Technology",
    description:
      "Finding new and innovative ways to develop websites and mobile apps is constantly evolving. From CMS and API integration to the simplest responsive design, our website tech specialist produce the best solutions for you.",
  },
];
