import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";

const PayrollServices = () => {
  const header = "";
  const strong = "";
  const description = "";

  return (
    <div>
      <CommonBanner currentPage="PAYROLL SERVICES" heading="PAYROLL SERVICES" />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <div
        style={{
          padding: "0 4%",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div className="webdesigndevelopment_section_card_container">
          {/* {HealthcareStaffingData.map((item, i) => {
            return (
              <div className="webdesigndevelopment_card_container" key={i}>
                <div className="webdesigndevelopment__card_icon">
                  <img src={item.icon} alt="" srcSet="" />
                </div>
                <div className="section_3_card_title">{item.title}</div>
                <div className="webdesigndevelopment_card_description">
                  {item.description}
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default PayrollServices;
