import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import TalentStaffingModel from "../../components/TalentStaffingModel";

const CreativeAndMarketingStaffing = () => {
  const header =
    "Creative & Marketing Staffing: Finding the Right Talent to Elevate Your Brand and Marketing Strategies";
  const strong = "";
  const description =
    "At BNI, we provide Creative and Marketing Staffing solutions to help you attract the best designers, marketers, and brand strategists. Whether you’re scaling your team for a campaign or filling a permanent creative position, we connect you with top-tier talent that drives brand growth, engages customers, and delivers impactful marketing results.";

  return (
    <div>
      <CommonBanner
        currentPage="CREATIVE AND MARKETING STAFFING"
        heading="CREATIVE AND MARKETING STAFFING"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <TalentStaffingModel />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default CreativeAndMarketingStaffing;
