import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import TalentStaffingModel from "../../components/TalentStaffingModel";

const EngineeringStaffing = () => {
  const header =
    "Engineering Staffing Solutions: Connecting You with Skilled Engineers to Drive Innovation and Success";
  const strong = "";
  const description =
    "At BNI, our Engineering Staffing services provide you with highly qualified engineers across various specialties. Whether you need support for a short-term project or a long-term hire, we help you find the right talent to fuel your engineering initiatives. Our tailored solutions ensure your teams are equipped with the expertise required to solve complex challenges and drive innovation.";

  return (
    <div>
      <CommonBanner
        currentPage="ENGINEERING STAFFING"
        heading="ENGINEERING STAFFING"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <TalentStaffingModel />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default EngineeringStaffing;
